const NAMESPACE = 'ADDRESSBOOK';
// Retrieve the list of registered users
export const ADDRESSBOOK_FETCH_REQ = `${NAMESPACE}/ADDRESSBOOK_FETCH_REQ`;
export const ADDRESSBOOK_FETCH_OK = `${NAMESPACE}/ADDRESSBOOK_FETCH_OK`;
export const ADDRESSBOOK_FETCH_FAIL = `${NAMESPACE}/ADDRESSBOOK_FETCH_FAIL`;
// New wallet from subscription
export const NEW_REGISTRATION_EVENT = `${NAMESPACE}/NEW_REGISTRATION_EVENT`;
// Firebase
export const ADDRESSBOOK_SUBSCRIBE_REQ = `${NAMESPACE}/ADDRESSBOOK_SUBSCRIBE_REQ`;
export const ADDRESSBOOK_LOAD_OK = `${NAMESPACE}/ADDRESSBOOK_LOAD_OK`;
export const ADDRESSBOOK_UPDATE_OK = `${NAMESPACE}/ADDRESSBOOK_UPDATE_OK`;

// Write address
export const ADDRESSBOOK_WRITE_REQ = `${NAMESPACE}/ADDRESSBOOK_WRITE_REQ`;
export const ADDRESSBOOK_WRITE_OK = `${NAMESPACE}/ADDRESSBOOK_WRITE_OK`;
export const ADDRESSBOOK_WRITE_FAIL = `${NAMESPACE}/ADDRESSBOOK_WRITE_FAIL`;
